<template>
    <b-overlay :show="showLoader">
        <b-card>
            <b-card title="Seznam bonusov za točke zvestobe" v-if="configs">
                <b-table id="table" responsive striped bordered hover :items="configs" :fields="fields" :per-page="pagination.per_page" :current-page="pagination.current_page">
                    <template #cell(date)="row">
                        <div class="d-flex justify-content-center">
                            {{ getDate(new Date(row.item.date)) }}, {{ getHours(new Date(row.item.date)) }}
                        </div>
                    </template>
                    <template #cell(actions)="row" v-if="$hasPermissions($permissions.EditCollectedPoints)">
                        <div class="d-flex justify-content-center">
                            <b-button class="mr-1" variant="warning" @click="setModalData(row)" v-b-modal.modal-1><fa icon="edit"/></b-button>
                        </div>
                    </template>
                </b-table>

                <b-modal id="modal-1" title="Uredi točke zvestobe">
                    <div v-if="modalData">
                        <b-form-group label="Opis">
                            <b-form-input v-model="modalData.reason"></b-form-input>
                        </b-form-group>

                        <b-form-group label="Točke">
                            <b-form-input v-model="modalData.points" type="number"> </b-form-input>
                        </b-form-group>
                    </div>

                    <template #modal-footer="{ ok, cancel }">
                        <b-button size="sm" variant="success" @click="ok(), updateConfig()">
                            Potrdi
                        </b-button>
                        <b-button size="sm" variant="danger" @click="cancel()">
                            Prekliči
                        </b-button>
                    </template>
                </b-modal>

                <b-row>
                    <b-col class="text-center text-sm-left">
                        <b-dropdown id="table-dropdown" variant="outline" :text="'Na stran: ' + pagination.per_page" size="sm" class="btn-none">
                            <b-dropdown-item v-model="pagination.per_page" v-for="(item, key) in pageOptions" :key="key"  @click="pagination.per_page = item">
                                {{ item }}
                            </b-dropdown-item>
                        </b-dropdown>
                    </b-col>
                    <b-col>
                        <b-pagination class="mt-1 mt-sm-0 justify-content-center justify-content-sm-end" id="table-pagination" pills 
                                      v-model="pagination.current_page" 
                                      :total-rows="pagination.total_items" 
                                      :per-page="pagination.per_page" 
                                      size="sm"
                        />
                    </b-col>
                </b-row>
            </b-card>
        </b-card>
    </b-overlay>
</template>

<script>
    import {BButton, BCard, BCol, BRow, BDropdown, BDropdownItem, BPagination, BTable, BModal, BFormGroup, BFormInput, BOverlay} from 'bootstrap-vue'

    export default {
        components:{
            BCard,
            BRow,
            BCol,
            BButton,
            BDropdown,
            BDropdownItem,
            BPagination,
            BTable,
            BModal,
            BFormGroup,
            BFormInput,
            BOverlay
        },
        data() {
            return {
                showLoader: false,
                modalData: null,
                configs: null,
                pageOptions: [10, 20, 50, 100],
                pagination: {current_page: 1, per_page: 10, total_items: 0},
                fields: [
                    { key: 'reason', label: 'Opis', sortable: true, class: 'text-center' },
                    { key: 'points', label: 'Točke', sortable: true, class: 'text-center' },
                    { key: 'date', label: 'Datum', sortable: true, class: 'text-center' }
                ]
            }
        },
        methods:{
            getDate(date) {
                return `${date.getDay()  }.${  date.getMonth() + 1  }.${  date.getFullYear()}`
            },
            getHours(date) {
                const minutes = date.getMinutes()
                let stringMinutes = ''

                if (minutes < 10) {
                    stringMinutes = `0${  minutes}`
                } else {
                    stringMinutes = minutes
                }

                return `${date.getHours()  }:${stringMinutes}`
            },
            async updateConfig() {
                this.showLoader = true
                try {

                    const payload = {
                        reason: this.modalData.reason,
                        points: parseInt(this.modalData.points),
                        internal_id: this.modalData.internal_id,
                        date: this.modalData.date,
                        id: this.modalData.id
                    }

                    await this.$http.patch('/api/management/v1/points/update_collected_points_config', payload)

                    await this.getConfigsData()
                } catch (err) {
                    this.$printError('Prislo je do napake')
                } finally {
                    this.showLoader = false
                }
            },
            setModalData(row) {
                this.modalData = { ...row.item }
            },
            async getConfigsData() {
                this.showLoader = true
                try {
                    const data = await this.$http.get('/api/management/v1/points/get_collected_points_config')
                    this.configs = data.data
                    this.pagination.total_items = this.configs.length
                } catch (err) {
                    this.$printError('Prislo je do napake')
                } finally {
                    this.showLoader = false
                }
            }
        },
        mounted() {
            this.getConfigsData()

            if (this.$hasPermissions(this.$permissions.EditCollectedPoints)) {
                this.fields.push({ key: 'actions', label: '', class: 'text-center' })
            }
        }
    }
</script>

<style lang="scss" scoped>
    #table .form-control, #table-dropdown, #table-dropdown .dropdown-menu {
        border: 1px solid #d8d6de;
         border-radius: 0.357rem;
    }
    #table-dropdown .dropdown-toggle::after, .drop .dropdown-toggle::after {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='grey' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-chevron-down'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E");
    }
    #table .custom-checkbox .custom-control-label{
        position: relative;
    }
</style>